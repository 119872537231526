var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "banderole" }),
    _c(
      "div",
      {
        staticClass:
          "footer-links is-hidden-mobile is-hidden-tablet-only has-text-centered",
      },
      [
        _c("router-link", { attrs: { to: "/imprint" } }, [_vm._v("Impressum")]),
        _c("router-link", { attrs: { to: "/booking" } }, [_vm._v("Kontakt")]),
        _c("router-link", { attrs: { to: "/gdpr" } }, [_vm._v("Datenschutz")]),
        _c(
          "a",
          { attrs: { href: "/files/agb_vermietung.pdf", target: "_blank" } },
          [_vm._v("AGB")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "mobile-footer-links is-hidden-desktop" }, [
      _c("div", { staticClass: "columns is-mobile is-multiline" }, [
        _c(
          "div",
          { staticClass: "column has-text-centered is-6-tablet is-6-mobile" },
          [
            _c("router-link", { attrs: { to: "/imprint" } }, [
              _vm._v("Impressum"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column has-text-centered is-6-tablet is-6-mobile" },
          [
            _c("router-link", { attrs: { to: "/booking" } }, [
              _vm._v("Kontakt"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column has-text-centered is-6-tablet is-6-mobile" },
          [
            _c("router-link", { attrs: { to: "/gdpr" } }, [
              _vm._v("Datenschutz"),
            ]),
          ],
          1
        ),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "column has-text-centered is-6-tablet is-6-mobile" },
      [
        _c(
          "a",
          { attrs: { href: "/files/agb_vermietung.pdf", target: "_blank" } },
          [_vm._v("AGB")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }