<template>
  <div id="app">
    <div id="logo"></div>
    <navigation-bar />
    <router-view />
    <footer-bar />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import FooterBar from "@/components/FooterBar";
export default {
  name: "App",
  components: {
    FooterBar,
    NavigationBar
  }
};
</script>
