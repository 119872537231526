import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash
        };
      } else if (to.path.length > 1) {
        if (to.path === "/data") {
          return {
            selector: "#" + to.path.substr(1),
            offset: { x: 0, y: 100 }
          };
        } else if (to.path === "/pricing") {
          return {
            selector: "#" + to.path.substr(1),
            offset: { x: 0, y: 120 }
          };
        } else if (to.path === "/extras") {
          return {
            selector: "#" + to.path.substr(1),
            offset: { x: 0, y: 120 }
          };
        } else if (to.path === "/gallery") {
          return {
            selector: "#" + to.path.substr(1),
            offset: { x: 0, y: 120 }
          };
        } else {
          return { x: 0, y: 0 };
        }
      }
    }
  }
});

export default router;
