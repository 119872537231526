var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { attrs: { id: "logo" } }),
      _c("navigation-bar"),
      _c("router-view"),
      _c("footer-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }