<template>
  <b-navbar id="navbar" v-bind:fixed-top="true">
    <template #brand>
      <b-navbar-item tag="router-link" to="/home">
        <div class="logo"></div>
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item id="navlink-home" tag="router-link" to="/home">
        Home
      </b-navbar-item>
      <b-navbar-item id="navlink-data" tag="router-link" to="/data">
        Daten
      </b-navbar-item>
      <b-navbar-item id="navlink-pricing" tag="router-link" to="/pricing">
        Preis
      </b-navbar-item>
      <b-navbar-item class="nav-spacer" tag="div"></b-navbar-item>
      <b-navbar-item id="navlink-extras" tag="router-link" to="/extras">
        Extras
      </b-navbar-item>
      <b-navbar-item id="navlink-gallery" tag="router-link" to="/gallery">
        Galerie
      </b-navbar-item>
      <b-navbar-item id="navlink-booking" tag="router-link" to="/booking">
        Buchen
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      visible: false,
      transparent: false
    };
  }
};
</script>

<style lang="scss">
.navbar-start {
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}

/* Mobile */
@media screen and (max-width: 1023px) {
  .navbar-end {
    display: flex;
    justify-content: center;
  }

  .navbar-brand,
  .navbar-burger,
  .navbar {
    min-height: 4rem !important;
  }

  .navbar-burger {
    height: 4rem !important;
  }

  .nav-spacer {
    display: none;
  }

  html.has-navbar-fixed-top,
  body.has-navbar-fixed-top {
    padding-top: 4rem;
  }
}

/* Desktop or bigger */
@media screen and (min-width: 1024px) {
  .navbar-item,
  .navbar-link {
    align-items: flex-end;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: 1.2rem;
    text-shadow: 1px 0 2px #000, 0 -1px 1px #000, 0 1px 2px #000,
      -1px 0 1px #000;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-item.nav-spacer {
    width: 240px;
  }
}
</style>
