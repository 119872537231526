/* Main Styles */
import "@/scss/main.scss";

/* Font Awesome - optimized for chunks */
import(
  /* webpackChunkName: "fontawesome" */ "@fortawesome/fontawesome-free/css/fontawesome.min.css"
);
import(
  /* webpackChunkName: "fa-solid-css" */ "@fortawesome/fontawesome-free/css/solid.min.css"
);
import(
  /* webpackChunkName: "fa-solid" */ "@fortawesome/fontawesome-free/js/solid.min.js"
);

// CORE
import Vue from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import router from "./router";

/* Components */
import axios from "axios";

/* init components */
Vue.use(Buefy, {
  defaultSnackbarPosition: "is-top",
  defaultFirstDayOfWeek: 1,
  defaultDatetimeFormatter: date =>
    new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "CET"
    }).format(date),
  defaultDateFormatter: date =>
    new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "CET"
    }).format(date),
  defaultTimeFormatter: "HH:mm",
  defaultIconPack: "fas"
});

Vue.config.productionTip = false;

axios.defaults.baseURL = "http://localhost:8080";

const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch("/runtimeConfig.json");
  return await runtimeConfig.json();
};

getRuntimeConfig()
  .then(json => {
    const config = {
      endpoint: json.API_ENDPOINT,
      price: json.PRICE
    };

    // override for local-env
    if (process.env.VUE_APP_API_ENDPOINT !== undefined) {
      config.endpoint = process.env.VUE_APP_API_ENDPOINT;
    }
    if (process.env.VUE_APP_PRICE !== undefined) {
      config.price = process.env.VUE_APP_PRICE;
    }

    Vue.prototype.$runtimecfg = config;
    axios.defaults.baseURL = config.endpoint;
  })
  .then(() => {
    new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  });
