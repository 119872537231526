<template>
  <footer class="footer">
    <div class="banderole"></div>
    <div
      class="footer-links is-hidden-mobile is-hidden-tablet-only has-text-centered"
    >
      <router-link to="/imprint">Impressum</router-link>
      <router-link to="/booking">Kontakt</router-link>
      <router-link to="/gdpr">Datenschutz</router-link>
      <a href="/files/agb_vermietung.pdf" target="_blank">AGB</a>
    </div>
    <div class="mobile-footer-links is-hidden-desktop">
      <div class="columns is-mobile is-multiline">
        <div class="column has-text-centered is-6-tablet is-6-mobile">
          <router-link to="/imprint">Impressum</router-link>
        </div>
        <div class="column has-text-centered is-6-tablet is-6-mobile">
          <router-link to="/booking">Kontakt</router-link>
        </div>
        <div class="column has-text-centered is-6-tablet is-6-mobile">
          <router-link to="/gdpr">Datenschutz</router-link>
        </div>
        <div class="column has-text-centered is-6-tablet is-6-mobile">
          <a href="/files/agb_vermietung.pdf" target="_blank">AGB</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar"
};
</script>
