var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-navbar", {
    attrs: { id: "navbar", "fixed-top": true },
    scopedSlots: _vm._u([
      {
        key: "brand",
        fn: function () {
          return [
            _c(
              "b-navbar-item",
              { attrs: { tag: "router-link", to: "/home" } },
              [_c("div", { staticClass: "logo" })]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "start",
        fn: function () {
          return [
            _c(
              "b-navbar-item",
              {
                attrs: { id: "navlink-home", tag: "router-link", to: "/home" },
              },
              [_vm._v(" Home ")]
            ),
            _c(
              "b-navbar-item",
              {
                attrs: { id: "navlink-data", tag: "router-link", to: "/data" },
              },
              [_vm._v(" Daten ")]
            ),
            _c(
              "b-navbar-item",
              {
                attrs: {
                  id: "navlink-pricing",
                  tag: "router-link",
                  to: "/pricing",
                },
              },
              [_vm._v(" Preis ")]
            ),
            _c("b-navbar-item", {
              staticClass: "nav-spacer",
              attrs: { tag: "div" },
            }),
            _c(
              "b-navbar-item",
              {
                attrs: {
                  id: "navlink-extras",
                  tag: "router-link",
                  to: "/extras",
                },
              },
              [_vm._v(" Extras ")]
            ),
            _c(
              "b-navbar-item",
              {
                attrs: {
                  id: "navlink-gallery",
                  tag: "router-link",
                  to: "/gallery",
                },
              },
              [_vm._v(" Galerie ")]
            ),
            _c(
              "b-navbar-item",
              {
                attrs: {
                  id: "navlink-booking",
                  tag: "router-link",
                  to: "/booking",
                },
              },
              [_vm._v(" Buchen ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }