export const routes = [
  {
    path: "/",
    name: "Homepage",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/data",
    name: "Data",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/extras",
    name: "Extras",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/booking",
    name: "booking",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/Booking.vue"),
    meta: {
      sitemap: {
        priority: 0.8
      }
    }
  },
  {
    path: "/gdpr",
    name: "gdpr",
    component: () => import(/* webpackChunkName: "gdpr" */ "../views/GDPR.vue"),
    meta: {
      sitemap: {
        priority: 0.5,
        changefreq: "yearly"
      }
    }
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () =>
      import(/* webpackChunkName: "imprint" */ "../views/Imprint.vue"),
    meta: {
      sitemap: {
        priority: 0.5,
        changefreq: "yearly"
      }
    }
  }
];
